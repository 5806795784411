<template>
  <div id="Loss">
    <b-row class="m-0">
      <b-col xl="1"></b-col>
      <b-col xl="10">
        <b-card header-tag="nav" class="mt-4 mb-4">
          <template v-slot:header>
            <b-navbar toggleable="lg" class="text-center">
              <b-navbar-brand href="#">
                <router-link class="text-dark" :to="{ name: 'loss.view' }">{{
                  $t("loss.title")
                }}</router-link>
              </b-navbar-brand>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                  <b-link class="ml-3 p-0 text-muted" @click="openSettings" :disabled="true">
                    <div class="h3 m-0 p-0">
                      <i class="fas fa-cog"></i>
                    </div>
                  </b-link>
                </b-navbar-nav>
            </b-navbar>
          </template>
          <inputs />
        </b-card>
      </b-col>
      <b-col xl="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import inputs from "./input";
export default {
  name: "loss.index",
  components: {
    inputs: inputs,
  },
  methods: {
    openSettings() {
      return false
    }
  },
  created() {
    const query = this.$route.query;
    if (query["u"] !== undefined) {
      if (query["u"] === "metric") {
        this.$store.commit("setLossPropertiesPiUnit", "bar");
        this.$store.commit("setLossPropertiesTiUnit", "degc");
        this.$store.commit("setLossMassFlowUnit", "kg/s");
      } else if (query["u"] === "imperial") {
        this.$store.commit("setLossPropertiesPiUnit", "psi");
        this.$store.commit("setLossPropertiesTiUnit", "degf");
        this.$store.commit("setLossMassFlowUnit", "lb/s");
      } else if (query["u"] === "si") {
        this.$store.commit("setLossPropertiesPiUnit", "mpa");
        this.$store.commit("setLossPropertiesTiUnit", "kelvin");
        this.$store.commit("setLossMassFlowUnit", "kg/s");
      }
    }
    if (query["fu"] !== undefined) {
      if (query["fu"] === "Kv") {
        this.$store.commit("setLossPartsFlowUnit", {
          index: 0,
          flowUnit: "Kv",
        });
        this.$store.commit("setLossPartsFlowUnit", {
          index: 1,
          flowUnit: "Kv",
        });
      } else if (query["fu"] === "Cv") {
        this.$store.commit("setLossPartsFlowUnit", {
          index: 0,
          flowUnit: "Cv",
        });
        this.$store.commit("setLossPartsFlowUnit", {
          index: 1,
          flowUnit: "Cv",
        });
      }
    }
    if (query["cur"] !== undefined) {
      if (query["cur"] === "EUR") {
        this.$store.commit("setLossMWCurrency", "EUR");
        this.$store.commit("setLossCurrency", "EUR");
      } else if (query["cur"] === "GBP") {
        this.$store.commit("setLossMWCurrency", "GBP");
        this.$store.commit("setLossCurrency", "GBP");
      } else if (query["cur"] === "USD") {
        this.$store.commit("setLossMWCurrency", "USD");
        this.$store.commit("setLossCurrency", "USD");
      }
    }
    if (
      query["u"] !== undefined ||
      query["fu"] !== undefined ||
      query["cur"] !== undefined
    ) {
      this.$router.push("loss");
    }
  },
};
</script>

<style>
</style>