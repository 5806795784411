<template>
  <div id="loss.input.index">
    <b-row>
      <b-col md="12">
        <h1 class="h3 text-center">{{$t('loss.properties.title')}}</h1>
      </b-col>
    </b-row>
    <b-row v-if="this.requestError !== null">
      <b-col md="12">
        <b-alert
        show
        variant="danger"
        >{{this.requestError}}</b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12">
        <properties />
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col md="12">
        <h1 class="h3 text-center">{{$t('loss.valve.title')}}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="8">
        <parts />
      </b-col>
      <b-col xl="4">
        <economics />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="ml-1">{{$t('loss.form.general.required-text')}}</b-col>
    </b-row>
  </div>
</template>

<script>
import properties from "../input/properties";
import parts from "../input/parts";
import economics from "../input/economics"
import { mapGetters } from 'vuex';
export default {
  name: "loss.input.index",
  components: {
    properties: properties,
    parts: parts,
    economics: economics
  },
  computed: {
    ...mapGetters({
      requestError: "getLossRequestError"
    })
  }
};
</script>

<style>
</style>