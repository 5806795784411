<template>
  <div>
    <b-form-group class="mb-2">
      <label for="MW-cur-live">{{$t('loss.form.economics.mwh.label')}}:</label>
      <b-input-group>
        <template v-slot:prepend>
          <b-form-select
            id="MW-cur-live"
            tabindex="-1"
            v-model="mwcurrency"
            :options="$t('loss.form.economics.mwh.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="MW-price-live"
          type="number"
          v-model="$v.mwprice.$model"
          :state="validateState('mwprice')"
          aria-describedby="MW-price-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.mwprice.$anyError" id="MW-price-feedback">
        <small
          v-if="!$v.mwprice.required"
          class="form-text text-danger"
        >{{$t('loss.form.economics.mwh.required')}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "loss.form.mwprice",
  computed: {
    ...mapGetters({
      economics: "getLossEconomics",
    }),
    mwprice: {
      get() {
        return this.economics.mw.value;
      },
      set(value) {
        this.$store.commit("setLossMWPrice", value);
      },
    },
    mwcurrency: {
      get() {
        return this.economics.mw.currency;
      },
      set(value) {
        this.$store.commit("setLossMWCurrency", value);
      },
    },
  },
  validations: {
    mwprice: {
      
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
};
</script>

<style>
</style>