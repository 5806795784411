<template>
  <div id="loss.form.runtime">
    <b-form-group class="mb-2" id="runtime-group">
      <label for="runtime-live">{{$t('loss.form.economics.runtime.label')}}:</label>
      <b-input-group>
        <template v-slot:append>
          <b-input-group-text>
            <strong>{{$t('loss.form.economics.runtime.unit')}}</strong>
          </b-input-group-text>
        </template>
        <b-form-input
          id="runtime-live"
          type="number"
          v-model="$v.runtime.$model"
          :state="validateState('runtime')"
          aria-describedby="runtime-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.runtime.$anyError" id="runtime-feedback">
        <small
          v-if="!$v.runtime.required"
          class="form-text text-danger"
        >{{$t('loss.form.economics.runtime.required')}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "loss.form.runtime",
  computed: {
    ...mapGetters({
      economics: "getLossEconomics",
    }),
    runtime: {
      get() {
        return this.economics.runtime.value;
      },
      set(value) {
        this.$store.commit("setLossRunTime", value);
      },
    },
  },
  validations: {
    runtime: {
      
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
};
</script>

<style>
</style>