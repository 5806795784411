<template>
  <div id="loss.form.part.flowValue">
    <b-form-group class="mb-2" id="flow-coefficient-group">
      <label for="flow-coefficient-live">{{$t('loss.form.parts.flowCoefficient.label')}}: {{$t('loss.form.general.required')}}</label>
      <b-input-group>
        <template v-slot:append>
          <b-form-select
            id="valve-options-live"
            v-model="flowUnit"
            tabindex="-1"
            :options="$t('loss.form.parts.flowCoefficient.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="flow-coefficient-live"
          type="number"
          v-model="$v.flowValue.$model"
          :state="validateState('flowValue')"
          aria-describedby="flow-coefficient-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.flowValue.$anyError" id="flow-coefficient-feedback">
        <small
          v-if="!$v.flowValue.required"
          class="form-text text-danger"
        >{{$t('loss.form.parts.flowCoefficient.required')}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from '@/scripts';
export default {
  name: "loss.form.part.flowValue",
  props: ["index"],
  computed: {
    ...mapGetters({
      parts: "getLossParts",
    }),
    flowValue: {
      get() {
        return this.parts[this.index].flowValue;
      },
      set(value) {
        this.$store.dispatch("storeLossFlowValue", {index: this.index, flowValue: value})
      }
    },
    flowUnit: {
      get() {
        return this.parts[this.index].flowUnit;
      },
      set(value) {
        this.$store.dispatch("storeLossFlowUnit", {index: this.index, flowUnit: value})
      }
    },
  },
  validations: {
    flowValue: {
      required,
      valid() {
        return logic.input.loss.parts.flowCoefficient.validate(
          this.parts[this.index].flowValue,
          this.parts[this.index].flowUnit
        );
      },
    },
    flowUnit: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>

<style>
</style>