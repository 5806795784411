<template>
  <div id="loss.properties">
    <b-row>
        <b-col xl="4">
            <pressure />
        </b-col>
        <b-col xl="4">
            <temperature />
        </b-col>
        <b-col xl="4">
            <massflow />
        </b-col>
    </b-row>
  </div>
</template>

<script>
import pressure from '@/views/loss/form/properties/pressure'
import temperature from '@/views/loss/form/properties/temperature'
import massflow from '@/views/loss/form/properties/massflow'
export default {
    name: "loss.properties.index",
    components: {
        pressure: pressure,
        temperature: temperature,
        massflow: massflow
    }
}
</script>

<style>

</style>