<template>
  <div id="loss.economics.result">
    <hr v-if="calculated && hasEconomicResults" />
    <b-row v-if="calculated" class="text-center">
      <b-col md="12">
        <b-row v-if="results.economics.hour && results.economics.annual">
          <b-col xl="6">
            <b-row>
              <b-col md="12">
                <label>{{$t('loss.economics.result.hour')}}:</label>
              </b-col>
              <b-col md="12">
                <label>
                  {{getCurrencySign(results.economics.hour[1])}}
                  {{results.economics.hour[0]}}
                </label>
              </b-col>
            </b-row>
          </b-col>
          <b-col xl="6">
            <b-row>
              <b-col md="12">
                <label>{{$t('loss.economics.result.annual')}}:</label>
              </b-col>
              <b-col md="12">
                <label>
                  {{getCurrencySign(results.economics.annual[1])}}
                  {{results.economics.annual[0]}}
                </label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="results.economics.hour && !results.economics.annual">
          <b-col xl="12">
            <b-row>
              <b-col md="12">
                <label>{{$t('loss.economics.result.hour')}}:</label>
              </b-col>
              <b-col md="12">
                <label>
                  {{getCurrencySign(results.economics.hour[1])}}
                  {{results.economics.hour[0]}}
                </label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="!results.economics.hour && results.economics.annual">
          <b-col xl="12">
            <b-row>
              <b-col md="12">
                <label>{{$t('loss.economics.result.annual')}}:</label>
              </b-col>
              <b-col md="12">
                <label>
                  {{getCurrencySign(results.economics.annual[1])}}
                  {{results.economics.annual[0]}}
                </label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="results.economics.breakeven">
          <b-col md="12">
            <b-row>
              <b-col md="12">
                <label>{{$t('loss.economics.result.breakEven')}}:</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <label>{{parseBreakEvenTime(results.economics.breakeven)}}</label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "loss.economics.result",
  computed: {
    ...mapGetters({
      calculated: "hasCalculatedLoss",
      isLoading: "isLoadingLoss",
      isCalculable: "isCalculable",
      results: "getLossResults",
    }),
    hasEconomicResults() {
      const economics = this.results.economics
      return economics.hour || economics.annual || economics.breakEven
    }
  },
  methods: {
    getCurrencySign(currency) {
      if (currency === "EUR") {
        return "€";
      } else if (currency === "GBP") {
        return "£";
      } else if (currency === "USD") {
        return "$";
      }
    },
    parseBreakEvenTime(time) {
      var years = "";
      var months = "";
      var hours = "";
      if (time.year != 0) {
        years = `${time.year}`;
        if (time.year > 1) {
          years += " years";
        } else {
          years += " year";
        }
      }
      if (time.month != 0) {
        months = `${time.month}`;
        if (time.month > 1) {
          months += " months";
        } else {
          months += " month";
        }
      }
      if (time.hour != 0) {
        hours = `${time.hour}`;
        if (time.hour > 1) {
          hours += " hours";
        } else {
          hours += " hour";
        }
      }
      return `${years} ${months} ${hours}`
    },
  },
};
</script>

<style>
</style>