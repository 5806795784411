<template>
  <div>
    <b-form-group class="mb-2">
      <label for="cur-live">{{$t('loss.form.economics.price.label')}}:</label>
      <b-input-group>
        <template v-slot:prepend>
          <b-form-select
            id="cur-live"
            tabindex="-1"
            v-model="currency"
            :options="$t('loss.form.economics.price.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="price-live"
          type="number"
          v-model="$v.price.$model"
          :state="validateState('price')"
          aria-describedby="price-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.price.$anyError" id="price-feedback">
        <small
          v-if="!$v.price.required"
          class="form-text text-danger"
        >{{$t('loss.form.economics.price.required')}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "loss.form.price",
  computed: {
    ...mapGetters({
      economics: "getLossEconomics",
    }),
    price: {
      get() {
        return this.economics.price.value;
      },
      set(value) {
        this.$store.commit("setLossPrice", value);
      },
    },
    currency: {
      get() {
        return this.economics.price.currency;
      },
      set(value) {
        this.$store.commit("setLossCurrency", value);
      },
    },
  },
  validations: {
    price: {
      
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
};
</script>

<style>
</style>