<template>
  <div id="loss.parts">
    <b-row>
        <b-col xl="6">
            <part index="0" />
        </b-col>
        <b-col xl="6">
            <part index="1" />
        </b-col>
    </b-row>
  </div>
</template>

<script>
import part from './part'
export default {
    name: "loss.parts.index",
    components: {
        part: part
    }
}
</script>

<style>

</style>