<template>
  <div id="loss.economics">
    <b-card class="mb-2">
      <template v-slot:header>
        <b-navbar>
          <b-navbar-brand>{{$t(`loss.economics.title`)}}</b-navbar-brand>
        </b-navbar>
      </template>
      <b-row>
        <b-col md="12">
          <mwprice />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <price />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <runtime />
        </b-col>
      </b-row>
      <result/>
      <calculateButton />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mwprice from "@/views/loss/form/economics/mwprice";
import price from "@/views/loss/form/economics/price";
import runtime from "@/views/loss/form/economics/runtime";
import calculateButton from "./calculate"
import result from "./result"
export default {
  name: "loss.form.parts.part",
  props: ["index"],
  components: {
    mwprice,
    price,
    runtime,
    calculateButton,
    result
  },
  computed: {
    ...mapGetters({
      calculated: "hasCalculatedLoss",
      economics: "getLossEconomics",
      results: "getLossResults",
    }),
  },
  
};
</script>

<style>
</style>