<template>
  <div id="loss.economics.calculate.button">
    <b-row>
      <b-col>
        <div class="text-center">
          <b-button
            v-if="!isLoading"
            :disabled="!isCalculable"
            variant="primary"
            class="mt-3"
            @click="calculate"
          >
            <i class="fas fa-calculator"></i>
            {{$t('loss.form.buttons.calculate')}}
          </b-button>
          <loading-button v-else class="mt-3" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loadingButton from "@/components/general/loadingbutton.vue";
export default {
  name: "loss.economics.calculate.button",
  components: {
    loadingButton,
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoadingLoss",
      isCalculable: "isCalculable",
      properties: "getLossProperties",
      mdot: "getLossMassFlow",
      parts: "getLossParts",
    }),
  },
  methods: {
    calculate() {
      this.$store.dispatch("calculateLoss");
    },
  },
};
</script>

<style>
</style>