<template>
  <div id="loss.form.parts.part">
    <b-card class="mb-2">
      <template v-slot:header>
        <b-navbar>
          <b-navbar-brand>{{$t(`loss.valve.parts[${index}].title`)}}</b-navbar-brand>
        </b-navbar>
      </template>
      <b-row>
        <b-col md="12">
          <flowValue v-bind:index="index" />
        </b-col>
      </b-row>
      <hr v-if="calculated" />
      <b-row v-if="calculated" class="text-center">
        <b-col>
          <b-row>
            <b-col md="6">
              <b-row>
                <b-col md="12">
                  <label>{{$t(`loss.valve.parts[${index}].result.pressure`)}}:</label>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <label>{{results['parts'][index]["Pe"][0]}} {{this.getPressureUnit(results['parts'][index]["Pe"][1])}}</label>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  md="12"
                >↓ {{results['parts'][index]["dP"][0]}} {{this.getPressureUnit(results['parts'][index]["dP"][1])}}</b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="12">
                  <label>{{$t(`loss.valve.parts[${index}].result.temperature`)}}:</label>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <label>{{results['parts'][index]["Te"][0]}} {{this.getTemperatureUnit(results['parts'][index]["Te"][1])}}</label>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  md="12"
                >↓ {{results['parts'][index]["dT"][0]}} {{this.getTemperatureUnit(results['parts'][index]["dT"][1])}}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="12">
                  <label>{{$t(`loss.valve.parts[${index}].result.mwh`)}}:</label>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">{{results['parts'][index]["MW"][0]}} {{$tc('units.mwh.short',0)}}</b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import flowValue from "@/views/loss/form/part/flowValue";
import { mapGetters } from "vuex";
export default {
  name: "loss.form.parts.part",
  props: ["index"],
  components: {
    flowValue,
  },
  computed: {
    ...mapGetters({
      calculated: "hasCalculatedLoss",
      properties: "getLossProperties",
      results: "getLossResults",
    }),
  },
  methods: {
    getPressureUnit(system) {
      if (system === "metric") {
        return "bar";
      } else if (system === "imperial") {
        return "PSi";
      } else if (system === "si") {
        return "MPa";
      } else if (system == "bar") {
        return "bar"
      } else if (system == "psi") {
        return "PSi"
      } else if (system == "mpa") {
        return "MPa"
      }
    },
    getTemperatureUnit(system) {
      if (system === "metric") {
        return "°C";
      } else if (system === "imperial") {
        return "°F";
      } else if (system === "si") {
        return "Kelvin";
      } else if (system === "degc") {
        return "°C";
      } else if (system === "degf") {
        return "°F";
      } else if (system === "kelvin") {
        return "Kelvin";
      }
    },
  }
};
</script>

<style>
</style>